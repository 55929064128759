ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul li {
  display: inline-block;
  clear: both;
  padding: 20px;
  border-radius: 30px;
  margin-bottom: 2px;
  font-family: Helvetica, Arial, sans-serif;
}

.them {
  background: #eee;
  float: left;
  white-space: pre-line;
}

.me {
  float: right;
  background: #0084ff;
  color: #fff;
  white-space: pre-line;
}

img {
  width: 100%;
  max-width: 200px;
  height: 100%;
  max-width: 200px;
}
